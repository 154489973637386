import React from 'react'
import { NewsCard } from './NewsCard'
import { News } from '../pages/news'

export interface NewsCardListProps {
  newsList: News[] 
}

export const NewsCardList: React.FC<NewsCardListProps> = ({newsList}: NewsCardListProps) => {
  return (
	  <ul className="c-news-list">
      {newsList.map(news => (
        <NewsCard key={news.id} news={news} />
      ))}
    </ul>
  )
}

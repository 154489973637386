import React, {FC} from 'react'
import { MagazineBaseSite } from '../pages/magazine/[id]'

interface PickupCategoryLabelProps {
	category: MagazineBaseSite | null
}

export const PickupCategoryLabel: React.FC<PickupCategoryLabelProps> = ({ category }: PickupCategoryLabelProps) => {
  // refs: https://github.com/IJGN/betsudai_admin_web/blob/develop/app/models/magazine.rb#L48-L98
  return (
    <div>
      { (category != null)  && (
        <span data-type="tag" data-label={category.key}>{category.name}</span>
      )}
    </div>
  )
}

import React from 'react'
import Top from '../components/Top'
import { GetServerSideProps } from 'next';
import camelcaseKeys from 'camelcase-keys'
import { News } from './news'
import { Magazine } from '../pages/magazine/[id]'
import HeadMeta from '../components/HeadMeta'

export interface Event{
  id: number;
  name:string;
  category:string;
  pictureImageUrl:string;
  term:string;
  place:string;
  holdingStatus:string;
  url: string;
  hasGift: boolean
  eventTags: EventTag[];
}

export interface EventTag {
  name: string
}

export interface Banners {
  id: number
  title: string
  url: string
  bannerFull: string
  bannerSmall: string
  bannerMedium: string
  bannerLarge: string
}

export interface IndexProps{
  events: Event[]
  newsList: News[]
  pickupList: Magazine[]
  banners: Banners[]
}

const Home = ({events, newsList, pickupList, banners}: IndexProps) => {
  return (
    <div>
      <HeadMeta title="株式会社ベツダイ" description="大分のまちと人とともに57年。ベツダイは住まいのトータルクリエイティブカンパニーです。大分・福岡の新築・中古・賃貸・戸建て・マンション・リフォーム・リノベーションのことならベツダイにお任せください。" />

      <Top events={events} newsList={newsList} pickupList={pickupList} banners={banners} />

    </div>
  )
}

export default Home;

export const getServerSideProps:GetServerSideProps = async (ctx) =>{
  //TODO 共通化
  const authHeader = new Headers();
  authHeader.append('X-CLIENT-ID', process.env.CLIENT_ID || '');
  authHeader.append('X-CLIENT-SECRET', process.env.CLIENT_SECRET || '');
  // MEMO: refs: https://github.com/IJGN/betsudai_admin_web/blob/develop/app/models/article.rb#L20
  const display_site = '1';

  let events: Event[]
  try {
    const response = await fetch(`${process.env.API_HOST}/api/v1/events?number_page=8&page=1&display_site=${display_site}`, {
      headers: authHeader,
    })
    const data = await response.json()
    events = data.events
  } catch(e) {
    console.log(`ERROR: ${e}`)
    events = []
  }

  let pickupList: Magazine[]
  // MEMO: refs: https://github.com/IJGN/betsudai_admin_web/blob/develop/app/models/magazine.rb#L48-L96
  const displaySiteForMagazine = 'corporate';
  try {
    const response = await fetch(`${process.env.API_HOST}/api/v1/magazines?per_page=6&page=1&display_site=${displaySiteForMagazine}`, {
      headers: authHeader,
    })
    const data = await response.json()
    pickupList = data.magazines
  } catch(e) {
    console.log(`ERROR: ${e}`)
    pickupList = []
  }

  let newsList: News[]
  try {
    const response = await fetch(`${process.env.API_HOST}/api/v1/betsudai_news?number_page=3&page=1&display_site=1`, {
      headers: authHeader,
    })
    const data = await response.json()
    newsList = data.news
  } catch(e) {
    console.log(`ERROR: ${e}`)
    newsList = []
  }

  let banners: Banners[]
  try {
    const response = await fetch(`${process.env.API_HOST}/api/v1/banners?display_site=0`, {
      headers: authHeader,
    })
    const data = await response.json()
    banners = data.banners
  } catch(e) {
    console.log(`ERROR: ${e}`)
    banners = []
  }

  return {
    props: {
      events: camelcaseKeys(events, { deep: true }),
      newsList: camelcaseKeys(newsList, { deep: true }),
      pickupList: camelcaseKeys(pickupList, { deep: true }),
      banners: camelcaseKeys(banners, { deep: true }),
    }
  }
}

import React, {FC} from 'react'
import { News } from '../pages/news'
import { NewsCategoryLabel } from './NewsCategoryLabel'

export interface NewsCardProps {
  news: News
}

export const NewsCard: React.FC<NewsCardProps> = (props) => {
  const news = props.news
  const categorylabel = new Map<number, string>([[0, 'news'], [1, 'recruit']])
  const categoryName = new Map<number, string>([[0, 'お知らせ'], [1, '採用情報']])
  return (
    <li className="c-news-item">
      <a href={`/news/${news.id}`}>
        <p data-type="date">{news.publishedAt}</p>
        <NewsCategoryLabel category={news.category} />
        <p data-type="article">{news.title}</p>
      </a>
    </li>
  )
}

import React, {FC} from 'react'
import { News } from '../pages/news'

interface EventStatusIconProps {
	holdingStatus: string
}

export const EventStatusIcon: React.FC<EventStatusIconProps> = ({ holdingStatus}: EventStatusIconProps) => {

  interface HoldingInfo {
    text: string
    image: string
  }

  const HOLDING_STATUSES: Map<string, HoldingInfo> = new Map<string, HoldingInfo>([
    ['before', { text: '予約受付中', image: 'img/icon-event-accept.png'}],
    ['holding', { text: '開催中', image: 'img/icon-event-holding.png'}],
    ['always', { text: '常時開催', image: 'img/icon-event-alholding.png'}],
  ]);

  
  const holdingInfo = HOLDING_STATUSES.get(holdingStatus)!

  return (
    <img src={holdingInfo?.image} alt={holdingInfo?.text} />
  )
}

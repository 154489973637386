
import React, {FC} from 'react'
import { News } from '../pages/news'

interface NewsCategoryLabelProps {
	category: number
}

export const NewsCategoryLabel: React.FC<NewsCategoryLabelProps> = ({ category }: NewsCategoryLabelProps) => {

	interface CategoryData {
		label: string
		name: string
	}

  const categoryMap = new Map<number, CategoryData>([
  	[0, { label: 'news', name: 'お知らせ' }],
  	[1, { label: 'recruit', name: '採用情報' }],
		[2, { label: 'media', name: 'メディア' }]
  ])

  const categoryData = categoryMap.get(category)!
  return (
    <span data-type="tag" data-label={categoryData.label}>{categoryData.name}</span>
  )
}
